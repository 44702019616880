<template>
    <el-container>
        <el-main>
            <el-form ref="sendInfo" :model="sendInfo" label-width="120px" :rules="sendInfo_rules">
                <el-form-item label="开启配送：">
                    <el-switch v-model="sendInfo.is_open_send" :active-value="1" :inactive-value="0">
                    </el-switch>
                </el-form-item>
                <template v-if="sendInfo.is_open_send">
                    <el-form-item label="配送范围：" prop="send_scope">
                        <el-input v-model="sendInfo.send_scope" class="nomalwidth">
                            <template slot="append">公里</template>
                        </el-input>
                        <span class="tips">超过此配送范围，不支持配送</span>
                    </el-form-item>
                    <el-form-item label="最低起送费用：" prop="lowest_send_amount">
                        <el-input v-model="sendInfo.lowest_send_amount" class="nomalwidth">
                            <template slot="append">元</template>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="运费模式：" prop="type">
                        <el-radio-group v-model="sendInfo.type">
                            <el-radio :label="0">按距离收费</el-radio>
                            <el-radio :label="1">免费配送</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="配送价格：" v-if="sendInfo.type == 0">
                        <div style="display: flex;">
                            <el-form-item label="基础距离：" prop="basics_distance" label-width="100px">
                                <el-input v-model="sendInfo.basics_distance" class="halfwidth">
                                    <template slot="append">公里</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="基础配送费：" prop="basics_send_amount" label-width="140px">
                                <el-input v-model="sendInfo.basics_send_amount" class="halfwidth">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                        </div>
                        <div style="display: flex;margin-top: 20px;">
                            <el-form-item label="每增加：" prop="per_add" label-width="100px">
                                <el-input v-model="sendInfo.per_add" class="halfwidth">
                                    <template slot="append">公里</template>
                                </el-input>
                            </el-form-item>
                            <el-form-item label="配送费用增加：" prop="add_send_amount" label-width="140px">
                                <el-input v-model="sendInfo.add_send_amount" class="halfwidth">
                                    <template slot="append">元</template>
                                </el-input>
                            </el-form-item>
                        </div>
                    </el-form-item>
                    <template v-else>
                        <el-form-item label="免运费金额：订单满：" prop="order_full" label-width="240px">
                            <el-input v-model="sendInfo.order_full" class="halfwidth">
                                <template slot="append">元，全部配送范围内都免费</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="订单不满，另收取配送费用：" prop="order_no_full_send_amount" label-width="240px">
                            <el-input v-model="sendInfo.order_no_full_send_amount" class="halfwidth">
                                <template slot="append">元订单全额不满，按此设置收配送费用</template>
                            </el-input>
                        </el-form-item>
                    </template>
                </template>
            </el-form>
            <Preservation @preservation="saveFormSend"></Preservation>
        </el-main>
    </el-container>
</template>

<script>
import Preservation from '@/components/preservation';
export default {
    components: {
        Preservation,
    },
    data: function () {
        return {
            sendInfo: {
                is_open_send: 0,
                send_scope: '',
                lowest_send_amount: '',
                type: 0,
                basics_distance: '',
                basics_send_amount: '',
                per_add: '',
                add_send_amount: '',
                order_full: '',
                order_no_full_send_amount: ''
            },
            sendInfo_rules: {
                send_scope: [{ required: true, message: '请输入配送范围', trigger: 'blur' }],
                lowest_send_amount: [{ required: true, message: '请输入最低起送费用', trigger: 'blur' }],
                basics_distance: [{ required: true, message: '请输入基础距离', trigger: 'blur' }],
                basics_send_amount: [{ required: true, message: '请输入基础配送费', trigger: 'blur' }],
                per_add: [{ required: true, message: '请输入每增加公里', trigger: 'blur' }],
                add_send_amount: [{ required: true, message: '请输入每增加公里配送费用增加', trigger: 'blur' }],
                order_full: [{ required: true, message: '请输入订单满', trigger: 'blur' }],
                order_no_full_send_amount: [{ required: true, message: '请输入订单不满，另收取配送费用', trigger: 'blur' }]
            }
        };
    },
    created () {
        this.getSendInfo()
    },
    methods: {
        getSendInfo () {
            this.$axios.post(this.$api.set.sendSetinfo).then((res) => {
                if (res.code == 0) {
                    let sendInfo = res.result
                    if (sendInfo) {
                        this.sendInfo = {
                            is_open_send: sendInfo.is_open_send,
                            send_scope: sendInfo.send_scope,
                            lowest_send_amount: sendInfo.lowest_send_amount,
                            type: sendInfo.type,
                            basics_distance: sendInfo.basics_distance,
                            basics_send_amount: sendInfo.basics_send_amount,
                            per_add: sendInfo.per_add,
                            add_send_amount: sendInfo.add_send_amount,
                            order_full: sendInfo.order_full,
                            order_no_full_send_amount: sendInfo.order_no_full_send_amount
                        }
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        saveFormSend () {
            this.$refs['sendInfo'].validate((valid) => {
                if (valid) {
                    this.$axios.post(this.$api.set.sendSetEdit, this.sendInfo).then((res) => {
                        if (res.code == 0) {
                            this.$message.success('保存成功');
                            this.getSendInfo()
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.el-main {
    height: 100%;
    background: #fff;
    padding-bottom: 50px;
}

.formlogo {
    width: 120px;
    height: 120px;
    border: 1px solid #dcdfe6;
}

.logodiv {
    width: 120px;
    height: 120px;
    border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    div {
        font-size: 14px;
        color: #9a9a9a;
        margin-left: 3px;
    }
}

.formdiv {
    color: #9a9a9a;
}

.nomalwidth {
    width: 780px;
}

.halfwidth {
    width: 365px;
}

/deep/ .el-dialog__wrapper {
    display: flex;
    align-items: center;

    .el-dialog {
        margin: 0 auto !important;
    }

    .el-dialog__header {
        background-color: #f0f0f0;
    }
}

.tips {
    font-size: 14px;
    color: #9a9a9a;
    margin-left: 10px;
}
</style>
